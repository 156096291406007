<template>
  <v-container :class="{'pa-0':$vuetify.breakpoint.xsOnly}">
    <v-card flat>
      <v-card-title>{{ getClientName }}</v-card-title>
      <v-divider/>
      <v-list color="background" class="pa-0">
        <ProjectListComponent v-for="project in getProjects" :key="project.id" :project="project"/>
      </v-list>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close" color="primary">Terug naar klanten overzicht</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import ProjectListComponent from "@/components/clientoverview/ProjectListComponent";
import routeNames from "@/router/RouteNames";

export default {
  name: "TeamProjectOverview",
  components: {
    ProjectListComponent,
  },
  methods: {
    close() {
        this.$router.push({
          name: routeNames.TEAM_CLIENT_OVERVIEW,
          params: {teamId: this.$router.currentRoute.params.teamId}
        })
    }
  },
  computed: {
    getClient() {
      return this.$store.state.clientsModule.client
    },
    getProjects() {
      if (this.getClient && this.getClient.projects) {
        return this.getClient.projects
      } else {
        return []
      }
    },
    getClientName() {
      if (this.getClient) {
        return this.getClient.clientName
      } else {
        return null
      }
    }
  },
}
</script>