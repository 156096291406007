<template>
  <v-card tile flat>
    <v-list-item two-line>
      <v-list-item-avatar class="justify-center"
          :style="{'background-color': avatarBackgroundColor}">
        <v-icon :style="{'background-color': avatarIconColor}">mdi-tools</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="body-1 font-weight-medium">{{ project.projectName }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action class="ma-0">
        <v-btn
            icon
            :color="project.active ? 'primary':'red'"
            fab
            @click="toggleProjectActive"
        >
          <v-icon v-if="project.active">mdi-toggle-switch</v-icon>
          <v-icon v-else>mdi-toggle-switch-off</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
import randomColor from "randomcolor";

export default {
  name: "ProjectListComponent",
  props: ['project'],
  data() {
    return {
      archive: false,
    }
  },
  methods: {
    toggleProjectActive() {
      if (this.getClient.active) {
        const project = this.project
        project.active = !project.active
        this.$store.dispatch('teamsModule/toggleProjectActiveForTeam', {
          projectId: this.project.id,
          teamId: this.$router.currentRoute.params.teamId,
          clientId: this.project.clientId
        })
        this.$emit('error', null)
      } else {
        this.$emit('error', "Project kan niet op actief gezet worden, want de klant is niet actief.")
      }
    },
  },
  computed: {
    getClient() {
      return this.$store.state.clientsModule.client
    },
    avatarBackgroundColor() {
      return randomColor({
        seed: this.project.projectName,
        luminosity: 'dark'
      })
    },
    avatarIconColor() {
      return randomColor({
        seed: this.project.projectName,
        luminosity: 'light'
      })
    }
  }
}
</script>
